import React from 'react'
import { Link } from 'gatsby'
import shape1 from '../../assets/images/services/service-shape1.png'
import shape2 from '../../assets/images/services/service-shape2.png'
import erpcrm from '../../assets/images/icones/erpcrm.gif'
import database from '../../assets/images/icones/database.gif'
import projetspe from '../../assets/images/icones/projetspe.gif'

const NosServices = () => {
    return (
        <section className="services-area ptb-70 bg-e3fbff">
            <div className="container">
                <div className="section-title">
                    <h2>Vous avez un besoin particulier ? </h2>
                    <p>Les compétences de notre équipe peuvent repondre à vos besoins</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={erpcrm} alt="erp crm icone" />
                            </div>
                            <h3>
                                <div>
                                    ERP-CRM
                                </div>
                            </h3>
                            <p>Solution logicielle pour une gestion complète de votre entreprise</p>
                                
                            <Link to="/dolibarr" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>

                            <div className="shape1">
                                <img src={shape1} alt="shape" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="shape" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={database} alt="data base icone" />
                            </div>
                            <h3>
                                <div>
                                    Sauvegarde de donnée
                                </div>
                            </h3>
                            <p>Solution matérielle pour sécuriser vos données</p>

                            <Link to="/sauvegarde-de-donnee" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="shape" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="shape" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-item">
                            <div className="icon">
                                <img src={projetspe} alt="casque contact icone" />
                            </div>
                            <h3>
                                <div>
                                    Projet sur mesure
                                </div>
                            </h3>
                            <p>Développement particulier, conseils & préconisations</p>
                            
                            <Link to="/contact" className="default-btn">
                                <i className="flaticon-right"></i> 
                                En savoir plus <span></span>
                            </Link>
                            
                            <div className="shape1">
                                <img src={shape1} alt="shape" />
                            </div>
                            <div className="shape2">
                                <img src={shape2} alt="shape" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default NosServices