import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import NosServices from "../components/ServiceInformatique/NosServices"
import { Helmet } from "react-helmet"

const ServiceInformatique = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Service informatique</title>
        <meta name="description" content="Seekreet, une équipe spécialisée dans le data management. Mise en conformité et sécurisation de vos données. Mise en place de backup, fourniture de matériels." />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Service Informatique" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Service Informatique" 
      />
      <NosServices />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default ServiceInformatique